<template>
  <div class="navBar">
    <el-tabs v-model="tabActive" type="card" @tab-click="handleTabClick"
             @tab-remove="handleTabRemove" >
      <el-tab-pane v-for="item in visitedRoutes" :key="item.path"
                   :closable="true"
                   :name="item.path">
        <span slot="label" style="display: inline-block" @contextmenu.prevent="onTabContextmenu($event, item)">{{item.meta.title}}</span>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { handleActivePath } from '@/utils/routes'
export default {
  name:'dkNav',
  data() {
    return {
      tabActive: '2',
    }
  },
  computed: {
    ...mapGetters({
      visitedRoutes: 'tabs/visitedRoutes',
    }),
  },
  watch: {
    $route: {
      handler(route) {
        this.$nextTick(() => {
          this.addTabs(route)
        })
      },
      immediate: true,
    }
  },
  methods: {
    ...mapActions({
      addVisitedRoute: 'tabs/addVisitedRoute',
      delVisitedRoute: 'tabs/delVisitedRoute',
      delOthersVisitedRoutes: 'tabs/delOthersVisitedRoutes',
      delLeftVisitedRoutes: 'tabs/delLeftVisitedRoutes',
      delRightVisitedRoutes: 'tabs/delRightVisitedRoutes',
      delAllVisitedRoutes: 'tabs/delAllVisitedRoutes',
    }),
    handleTabClick(tab) {
      if (!this.isActive(tab.name))
        this.$router.push(this.visitedRoutes[tab.index])
    },
    onTabContextmenu(event, item) {
      let that = this;
      this.$contextmenu({
        items: [
          {
            label: "关闭左侧标签",
            onClick: () => {
              that.closeLeftTabs(item)
            },
          },
          {
            label: "关闭右侧标签",
            onClick: () => {
              that.closeRightTabs(item)
            },
          },
          {
            label: "关闭其他标签",
            onClick: () => {
              that.closeOthersTabs(item)
            },
          },
          {
            label: "关闭全部标签",
            onClick: () => {
              that.closeAllTabs()
            },
          },
        ],
        event,
        customClass: "custom-class",
        zIndex: 99999,
        minWidth: 230,
      });
      return false;
    },
    /**
     * 根据原生路径删除标签中的标签
     * @param rawPath 原生路径
     * @returns {Promise<void>}
     */
    async handleTabRemove(rawPath) {
      console.log(rawPath)
      await this.delVisitedRoute(rawPath)
      if (this.isActive(rawPath)) this.toLastTab()
    },
    /**
     * 跳转最后一个标签页
     */
    toLastTab() {
      const latestView = this.visitedRoutes.slice(-1)[0]
      if (latestView) this.$router.push(latestView)
      else this.$router.push('/')
    },
    /**
     * 添加标签页
     * @param tag route
     * @param init 是否是从router获取路由
     * @returns {Promise<void>}
     */
    async addTabs(tag, init = false) {
      let parentIcon = null
      if (tag.matched)
        for (let i = tag.matched.length - 2; i >= 0; i--)
          if (!parentIcon && tag.matched[i].meta.icon)
            parentIcon = tag.matched[i].meta.icon
      if (!parentIcon) parentIcon = 'menu-line'
      if (tag.name && tag.meta && tag.meta.tabHidden !== true) {
        const path = handleActivePath(tag, true)
        await this.addVisitedRoute({
          path: path,
          query: tag.query,
          params: tag.params,
          name: tag.name,
          matched: init
              ? [tag.name]
              : tag.matched.map((route) => route.components.default.name),
          parentIcon,
          meta: { ...tag.meta },
        })
        this.tabActive = path
      }
    },
    isNoClosable(tag) {
      return tag.meta && tag.meta.noClosable
    },
    isActive(path) {
      return path === handleActivePath(this.$route, true)
    },


    /**
     * 删除其他标签页
     * @returns {Promise<void>}
     */
    async closeOthersTabs(route) {
      if (route) {
        if(route.path != this.$route.path){

        await this.$router.push(route)
        }
        await this.delOthersVisitedRoutes(route.path)
      }
      // await this.closeMenu()
    },
    /**
     * 删除左侧标签页
     * @returns {Promise<void>}
     */
    async closeLeftTabs(route) {
      if (route) {
        if(route.path != this.$route.path){

        await this.$router.push(route)
        }
        await this.delLeftVisitedRoutes(route.path)
      }
      // await this.closeMenu()
    },
    /**
     * 删除右侧标签页
     * @returns {Promise<void>}
     */
    async closeRightTabs(route) {
      if (route) {
        if(route.path != this.$route.path){

        await this.$router.push(route)
        }
        await this.delRightVisitedRoutes(route.path)
      }
      // await this.closeMenu()
    },
    /**
     * 删除所有标签页
     * @returns {Promise<void>}
     */
    async closeAllTabs() {
      await this.delAllVisitedRoutes()
      this.toLastTab()
      // await this.closeMenu()
    },
  }
}
</script>

<style lang="less">
  .navBar {
    width: 100%;
    background: #FFFFFF;
  }
  .el-tabs__header{
    margin: 0 !important;
  }
</style>
