<template>
  <el-dialog
    v-bind="$attrs"
    width="75%"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    v-on="$listeners"
    @open="onOpen"
    @close="onClose"
  >
    <div class="dialogView">
      <div class="fucView">
        <div class="searchView">
          <el-input v-model="searchWord" placeholder="请输入文件名称" clearable>
          </el-input>
          <el-button type="primary" @click="onSearch">搜索</el-button>
          <el-button type="danger" @click="cancelSearch" v-if="searchWord"
            >取消</el-button
          >
        </div>
        <div class="btnView">
          <el-button type="primary" @click="handelConfirm">
            使用选择的{{
              state == "file"
                ? "文件"
                : state == "pic"
                ? "图片"
                : state == "video"
                ? "视频"
                : "文件"
            }}
          </el-button>
          <el-upload
            :action="upLoadApi"
            :data="{ id: folderId, role: 'admin' }"
            :headers="uploadHeader"
            name="file"
            :accept="accept"
            :show-file-list="false"
            :on-success="upLoadSuccessHandle"
            :on-error="upLoadErrorHandle"
            :before-upload="beforeUpLoadHadnle"
            :multiple="true"
          >
            <el-button type="primary">
              上传{{
                state == "file"
                  ? "文件"
                  : state == "pic"
                  ? "图片"
                  : state == "video"
                  ? "视频"
                  : "文件"
              }}
            </el-button>
          </el-upload>
        </div>
      </div>
      <div class="contentView">
        <div class="leftView" v-if="!isSearch">
          <el-scrollbar>
            <el-tree
              :data="treeData"
              :props="defaultProps"
              default-expand-all
              :allow-drop="allowDrop"
              @node-drop="nodeDrop"
              draggable
              @node-contextmenu="onTreeContextmenu"
              @node-click="handleNodeClick"
            >
              <template slot-scope="{ node, data }">
                <span :style="data.id == folderId ? 'color:#006eff;' : ''">{{
                  data.name
                }}</span>
              </template>
            </el-tree>
          </el-scrollbar>
        </div>
        <div class="rightView">
          <el-scrollbar>
            <div class="fileList">
              <div
                class="fileItem"
                v-for="(item, index) in fileList"
                @click="fileSelect(item, index)"
                @contextmenu.prevent="onFileContextmenu($event, item)"
              >
                <i class="el-icon-success selected" v-if="item.selected"></i>
                <el-image
                  class="fileCover"
                  :lazy="true"
                  :src="item.type == 'image' ? item.url : item.cover"
                  fit="contain"
                />
                <span class="filename">{{ item.name }}</span>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <div class="paginationView">
        <el-pagination
          layout="prev, pager, next"
          :page-size="50"
          :current-page.sync="page"
          :total="pageCount"
        />
      </div>
    </div>
    <el-dialog
      width="30%"
      title="移动至"
      :visible.sync="innerVisible"
      append-to-body
    >
      <el-cascader
        v-model="moveToId"
        :options="treeData"
        :props="{ checkStrictly: true, label: 'name', value: 'id' }"
        clearable
      ></el-cascader>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeInnerDialog">取 消</el-button>
        <el-button type="primary" @click="submitMove">确 定</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import {
  apiGetFileList,
  apiAddFolder,
  apiGetMenuList,
  apiDeleteFolder,
  apiRenameFolder,
  apiMoveFolder,
  apiRenameFile,
  apiDeleteFile,
  apiMoveFile,
  apiSearch,
} from "@/request/api";
import axios from "axios";
export default {
  inheritAttrs: false,
  name: "fileSelect",
  props: {
    state: {
      type: String,
      default: "",
    },
    multiple: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      innerVisible: false,
      fileList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      treeData: [],
      folderId: 0,
      upLoadApi: axios.defaults.baseURL + "base/fs/upload-file",
      accept:
        this.state == "file"
          ? "*"
          : this.state == "image"
          ? "image/*"
          : this.state == "video"
          ? "video/*"
          : "*",
      uploadHeader: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
      },
      page: 1,
      pageCount: 1,
      searchWord: "",
      moveToId: "",
      moveFileId: "",
      isSearch: false,
      isShowThumb: 0,
      loading: null,
      selectFile: [],
    };
  },
  watch: {
    page(val) {
      if (this.isSearch) {
        this.searchFile();
      } else {
        this.getFileList();
      }
    },
  },
  methods: {
    onSearch() {
      this.isSearch = true;
      this.page = 1;
      this.searchFile();
    },
    cancelSearch() {
      this.searchWord = "";
      this.isSearch = false;
      this.folderId = 0;
      this.getFileList();
    },
    searchFile() {
      apiSearch({
        page: this.page,
        "per-page": 50,
        keyword: this.searchWord,
        type: this.state,
      }).then((res) => {
        this.fileList = res.data.list;
        this.pageCount = res.data.pagination["totalCount"];
      });
    },
    onOpen() {
      this.getFileList();
      this.getMenuList();
      this.folderId = 0;
    },
    handleNodeClick(data) {
      this.folderId = data.id;
      this.page = 1;
      this.getFileList();
    },
    getMenuList() {
      apiGetMenuList().then((res) => {
        if (res.code == 200) {
          this.treeData = res.data;
          this.page = 1;
        }
      });
    },
    getFileList() {
      apiGetFileList({
        type: this.state,
        id: this.folderId,
        page: this.page,
        "per-page": 50,
      }).then((res) => {
        if (res.code == 200) {
          res.data.list.forEach((item) => {
            item.selected = false;
          });
          this.fileList = res.data.list;
          this.pageCount = res.data.pagination["totalCount"];
        }
      });
    },
    submitMove() {
      var folderId = this.moveToId[this.moveToId.length - 1];
      apiMoveFile({
        id: this.moveFileId,
        folder_id: folderId,
      }).then((res) => {
        if (res.code == 200) {
          this.closeInnerDialog();
          this.getFileList();
        }
      });
    },
    closeInnerDialog() {
      this.innerVisible = false;
      this.moveToId = "";
      this.moveFileId = "";
    },
    onClose() {},
    close(e) {
      this.fileList.forEach((item) => {
        item.selected = false;
      });
      this.selectFile = [];
      this.$emit("update:visible", false);
    },
    addFolder(value, id) {
      apiAddFolder({
        name: value,
        parent_id: id,
      }).then((res) => {
        if (res.code == 200) {
          this.getFileList();
          this.getMenuList();
        }
      });
    },
    delFolder(id) {
      apiDeleteFolder({
        id: id,
      }).then((res) => {
        if (res.code == 200) {
          if (id == this.folderId) {
            this.folderId = 0;
          }
          this.getFileList();
          this.getMenuList();
        }
      });
    },
    renameFolder(value, id) {
      apiRenameFolder({
        name: value,
        id: id,
      }).then((res) => {
        if (res.code == 200) {
          this.getFileList();
          this.getMenuList();
        }
      });
    },
    renameFile(value, id) {
      apiRenameFile({
        new_name: value,
        id: id,
      }).then((res) => {
        if (res.code == 200) {
          this.getFileList();
        }
      });
    },
    delFile(id) {
      apiDeleteFile({
        id: id,
      }).then((res) => {
        if (res.code == 200) {
          this.getFileList();
        }
      });
    },
    upLoadSuccessHandle() {
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.page = 1;
      this.getFileList();
      this.loading.close();
    },
    beforeUpLoadHadnle() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        fullscreen: true,
      });
    },
    upLoadErrorHandle() {
      this.$message({
        message: "上传失败",
        type: "error",
      });
      this.loading.close();
    },
    handelConfirm() {
      let that = this;
      let selectFile = [];
      this.selectFile.forEach((item) => {
        if (item.selected) {
          console.log(item);
          if (item.type == "image") {
            let obj = {
              id: item.id,
              thumb: item["thumbnail_obj"].url || item.url,
              origin: item.url,
            };
            selectFile.push(obj);
          }
          if (item.type == "application") {
            let obj = {
              id: item.id,
              cover: item.cover,
              url: item.url,
            };
            selectFile.push(obj);
          }
          if (item.type == "video") {
            let obj = {
              id: item.id,
              cover: item.cover,
              url: item.url,
            };
            selectFile.push(obj);
          }
        }
      });
      this.$emit("confirm", selectFile);
      this.close();
    },
    fileSelect(item) {
      if (this.multiple == 1) {
        item.selected = !item.selected;
        var index = this.fileList.findIndex(function (value, index, arr) {
          return value.id == item.id;
        });
        if (item.selected) {
          this.selectFile.push(item);
        } else {
          this.selectFile.splice(index, 1);
        }
      } else {
        this.fileList.forEach((item) => {
          item.selected = false;
        });
        item.selected = true;
        this.selectFile = [item];
      }
    },
    allowDrop(Node, dropNode, inner, event) {
      if (dropNode.level == 3) {
        return false;
      }
      return true;
    },
    nodeDrop(Node, dropNode, inner, event) {
      var id = Node.data.id;
      var parent_id = null;
      if (inner == "inner") {
        parent_id = dropNode.data.id;
      }
      if (inner == "after") {
        parent_id = dropNode.data.parent_id;
      }
      if (inner == "before") {
        parent_id = dropNode.data.parent_id;
      }
      apiMoveFolder({ id, parent_id }).then((res) => {
        this.getMenuList();
      });
    },
    onTreeContextmenu(event, data) {
      let that = this;
      this.$contextmenu({
        items: [
          {
            label: "新建文件夹",
            icon: "el-icon-folder-add",
            onClick: () => {
              this.$prompt("", "创建文件夹", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputPattern: /^[^\s]*$/,
                inputErrorMessage: "文件夹名不能为空",
              })
                .then(({ value }) => {
                  that.addFolder(value, data.id);
                })
                .catch(() => {});
            },
          },
          {
            label: "重命名",
            icon: "el-icon-edit",
            onClick: () => {
              this.$prompt("", "重命名文件夹", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputValue: data.name,
                inputPattern: /^[^\s]*$/,
                inputErrorMessage: "文件夹名不能为空",
              })
                .then(({ value }) => {
                  that.renameFolder(value, data.id);
                })
                .catch(() => {});
            },
          },
          {
            label: "删除",
            icon: "el-icon-delete",
            onClick: () => {
              this.$confirm("是否删除该文件夹及其文件?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  that.delFolder(data.id);
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消删除",
                  });
                });
            },
          },
        ],
        event,
        customClass: "custom-class",
        zIndex: 99999,
        minWidth: 230,
      });
      return false;
    },
    onFileContextmenu(event, item) {
      let that = this;
      this.$contextmenu({
        items: [
          {
            label: "移动",
            icon: "el-icon-rank",
            onClick: () => {
              this.innerVisible = true;
              this.moveFileId = item.id;
            },
          },
          {
            label: "删除",
            icon: "el-icon-delete",
            onClick: () => {
              this.$confirm("是否删除该文件?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  that.delFile(item.id);
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消删除",
                  });
                });
            },
          },
          {
            label: "重命名",
            icon: "el-icon-edit",
            onClick: () => {
              this.$prompt("", "重命名文件", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputValue: item.name,
                inputPattern: /^[^\s]*$/,
                inputErrorMessage: "文件名不能为空",
              })
                .then(({ value }) => {
                  that.renameFile(value, item.id);
                })
                .catch(() => {});
            },
          },
          {
            label: "获取链接",
            icon: "el-icon-link",
            onClick: () => {
                this.$copyText(item.url).then(function (e) {
                    that.$message.success('Copied!')
                }, function (e) {
                    that.$message.error('Can not copy!')
                })
            },
          },
        ],
        event,
        customClass: "custom-class",
        zIndex: 99999,
        minWidth: 230,
      });
      return false;
    },
  },
};
</script>

<style lang="less" scoped>
.dialogView {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  .fucView {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    height: 41px;
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .el-button {
      margin-left: 10px;
    }
  }
  .contentView {
    width: 100%;
    height: calc(100% - 86px);
    display: flex;
    flex-direction: row;

    .leftView {
      width: 250px;
      height: 100%;
      border-right: 1px solid #dcdfe6;
      padding: 0 5px;
      box-sizing: border-box;
      flex-shrink: 0;
    }
    .rightView {
      flex-grow: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 15px;
      box-sizing: border-box;
      .fileList {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0;
        padding-top: 0;
        box-sizing: border-box;
        .fileItem {
          width: 120px;
          height: 150px;
          margin-right: 10px;
          margin-top: 10px;
          padding-top: 10px;
          box-sizing: border-box;
          cursor: pointer;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;

          .fileCover {
            width: 100px;
            height: 100px;
            border-radius: 10px;
          }
          .filename {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 5px;
            box-sizing: border-box;
            text-align: center;
            margin-top: 5px;
          }
          .selected {
            position: absolute;
            font-size: 24px;
            color: #006eff;
            top: -8px;
            right: -8px;
            z-index: 20;
            background-color: #fff;
            border-radius: 50%;
          }
        }
        .fileItem:hover {
          //box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.25);
          //transform: scale(1.05);
          background-color: #f5f7fa;
        }
      }
    }
  }
  .paginationView {
    height: 45px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}
</style>
