<template>
  <div>
    <component :is="layout"/>
  </div>
</template>

<script>
import column from '@/dkui/layout/column'
export default {
  name: 'Layout',
  components:{column},
  data () {
    return {
      layout:'column'
    }

  }
}
</script>

<style scoped>

</style>