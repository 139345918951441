import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import VueClipboard from 'vue-clipboard2'



import 'element-ui/lib/theme-chalk/index.css'
// import '@/styles/theme/index.css';
import '@/styles/index.scss'
import '@/icons'
import axios from 'axios'
import Contextmenu from "vue-contextmenujs"
import Tinymce from '@/components/tinymce/index.vue'
import VueHotkey from 'v-hotkey'

Vue.component('tinymce', Tinymce)
Vue.config.productionTip = false;
Vue.prototype.$axios = axios
Vue.use(ElementUI)
Vue.use(Contextmenu)
Vue.use(VueHotkey)
Vue.use(VueClipboard)

// 路由拦截，判断是否需要登录
router.beforeEach((to, from, next) => {
  const token = JSON.parse(localStorage.getItem('token'))
  // 若需要登录访问，检查是否为登录状态
  if (!token && to.path !== '/404') {
    next({
      path: '/404'
    })
  } else {
    next()
  }
})


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
