<template>
    <el-scrollbar class="column-bar-container">
      <el-menu
          style="
          width: 200px;
          height: 100%;
          box-sizing: border-box;
          padding-bottom: 80px;
          overflow: hidden;
        "
          background-color="#282c34"
          text-color="#fff"
          active-text-color="#ffd04b"
          :default-active="routerIndex"
      >
        <template v-for="(item, index) in tabList">
          <template v-if="!item.meta.hidden">
            <el-submenu v-if="item.children.length > 0" :index="'' + index">
              <template slot="title">
                <i
                    :class="item.meta.icon ? item.meta.icon : 'el-icon-location'"
                ></i>
                <span>{{ item.meta.title }}</span>
              </template>
              <template v-for="(child, childIndex) in item.children">
                <el-menu-item
                    v-if="!child.meta.hidden"
                    :index="index + '-' + childIndex"
                    @click="changeRouter(child.fullpath)"
                >
                  <i
                      :class="child.meta.icon ? item.meta.icon : 'el-icon-location'"
                  ></i>
                  <span>{{ child.meta.title }}</span>
                </el-menu-item>
              </template>
            </el-submenu>
            <el-menu-item
                v-else
                :index="'' + index"
                @click="changeRouter(item.path)"
            >
              <i
                  :class="item.meta.icon ? item.meta.icon : 'el-icon-location'"
              ></i>
              <span>{{ item.meta.title }}</span>
            </el-menu-item>
          </template>
        </template>
      </el-menu>
    </el-scrollbar>
</template>

<script>
export default {
  name: "columnBar",
  data() {
    return {
      routerIndex: "0-0",
      tabList: [],
    };
  },
  watch: {
    $route: {
      handler(route) {
        this.setRouterIndex();
      },
      immediate: true,
    },
  },
  created() {
    var list = this.$router.options.routes;
    list.forEach((item) => {
      if (item.children.length > 0) {
        item.children.forEach((childItem) => {
          childItem.fullpath =
            item.path == "/"
              ? item.path + childItem.path
              : item.path + "/" + childItem.path;
        });
      }
    });
    this.tabList = list;
  },
  mounted() {
    this.setRouterIndex();
  },
  methods: {
    setRouterIndex() {
      var route = this.$route;
      var list = this.tabList;
      list.forEach((item, index) => {
        if (item.children.length > 0) {
          item.children.forEach((childItem, childindex) => {
            if (route.fullPath == childItem.fullpath) {
              this.routerIndex = index + "-" + childindex;
            }
          });
        } else {
          if (route.fullPath == item.path) {
            this.routerIndex = index;
          }
        }
      });
    },
    changeRouter(path) {
      if (path == this.$route.path) {
        return;
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.column-bar-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  background: #282c34;
  width: 200px;
  z-index: 230;

}

</style>
