import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../dkui/layout";

Vue.use(VueRouter);

const routes = [
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/login/login"),
    meta: {
      title: "404",
      hidden: true,
    },
    children: []
  },
  {
    path: "/",
    name: "Root",
    component: Layout,
    redirect: "/index",
    meta: {
      title: "首页",
    },
    children: [
      {
        path: "index",
        name: "index",
        component: () => import("@/views/test/test"),
        meta: {
          title: "测试",
        },
      },
    ],
  },
  {
    path: "/tools",
    name: "tools",
    component: Layout,
    meta: {
      title: "工具",
    },
    children: [
      {
        path: "translate",
        name: "translate",
        component: () => import("@/views/translate/index"),
        meta: {
          title: "翻译",
        },
      },
    ],
  },
  {
    path: "/inventory",
    name: "inventory",
    component: Layout,
    meta: {
      title: "进销存",
    },
    children: [
      {
        path: "purchaseHistory",
        name: "purchaseHistory",
        component: () => import("@/views/inventory/purchaseHistory"),
        meta: {
          title: "购货单记录",
        },
      },
      {
        path: "purchase",
        name: "purchase",
        component: () => import("@/views/inventory/purchase"),
        meta: {
          title: "购货单",
        },
      },
      {
        path: "returnPurchaseHistory",
        name: "returnPurchaseHistory",
        component: () => import("@/views/inventory/returnPurchaseHistory"),
        meta: {
          title: "退货单记录",
        },
      },
      {
        path: "returnPurchase",
        name: "returnPurchase",
        component: () => import("@/views/inventory/returnPurchase"),
        meta: {
          title: "退货单",
        },
      },
      {
        path: "customerOrderHistory",
        name: "customerOrderHistory",
        component: () => import("@/views/sales/customerOrderHistory"),
        meta: {
          title: "客户订单记录",
        },
      },
      {
        path: "customerOrder",
        name: "customerOrder",
        component: () => import("@/views/sales/customerOrder"),
        meta: {
          title: "客户订单",
        },
      },
      {
        path: "salesOrderHistory",
        name: "salesOrderHistory",
        component: () => import("@/views/sales/salesOrderHistory"),
        meta: {
          title: "销货单记录",
        },
      },
      {
        path: "salesOrder",
        name: "salesOrder",
        component: () => import("@/views/sales/salesOrder"),
        meta: {
          title: "货单",
        },
      },
      {
        path: "returnSalesOrderHistory",
        name: "returnSalesOrderHistory",
        component: () => import("@/views/sales/returnSalesOrderHistory"),
        meta: {
          title: "销货退货单记录",
        },
      },
      {
        path: "returnSalesOrder",
        name: "returnSalesOrder",
        component: () => import("@/views/sales/returnSalesOrder"),
        meta: {
          title: "销货退货单",
        },
      },
      {
        path: "allocateHistory",
        name: "allocateHistory",
        component: () => import("@/views/stock/allocateHistory"),
        meta: {
          title: "调拨单记录",
        },
      },
      {
        path: "allocateOrder",
        name: "allocateOrder",
        component: () => import("@/views/stock/allocateOrder"),
        meta: {
          title: "调拨单",
        },
      },
      {
        path: "stockInquiry",
        name: "stockInquiry",
        component: () => import("@/views/stock/stockInquiry"),
        meta: {
          title: "库存查询",
        },
      },
      {
        path: "outboundHistory",
        name: "outboundHistory",
        component: () => import("@/views/stock/outboundHistory"),
        meta: {
          title: "出库单记录",
        },
      },
      {
        path: "outboundOrder",
        name: "outboundOrder",
        component: () => import("@/views/stock/outboundOrder"),
        meta: {
          title: "出库单",
        },
      },
      {
        path: "warehouseHistory",
        name: "warehouseHistory",
        component: () => import("@/views/stock/warehouseHistory"),
        meta: {
          title: "入库单记录",
        },
      },
      {
        path: "warehouseOrder",
        name: "warehouseOrder",
        component: () => import("@/views/stock/warehouseOrder"),
        meta: {
          title: "入库单",
        },
      },
    ],
  },
  {
    path: "/capital",
    name: "capital",
    component: Layout,
    meta: {
      title: "资金",
    },
    children: [
      {
        path: "receiptHistory",
        name: "receiptHistory",
        component: () => import("@/views/capital/receiptHistory"),
        meta: {
          title: "收款单记录",
        },
      },
      {
        path: "receipt",
        name: "receipt",
        component: () => import("@/views/capital/receipt"),
        meta: {
          title: "收款单",
        },
      },
      {
        path: "paymentHistory",
        name: "paymentHistory",
        component: () => import("@/views/capital/paymentHistory"),
        meta: {
          title: "付款单记录",
        },
      },
      {
        path: "payment",
        name: "payment",
        component: () => import("@/views/capital/payment"),
        meta: {
          title: "付款单",
        },
      },
      {
        path: "otherIncomeHistory",
        name: "otherIncomeHistory",
        component: () => import("@/views/capital/otherIncomeHistory"),
        meta: {
          title: "其他收入单记录",
        },
      },
      {
        path: "otherIncome",
        name: "otherIncome",
        component: () => import("@/views/capital/otherIncome"),
        meta: {
          title: "其他收入单",
        },
      },
      {
        path: "otherExpenseHistory",
        name: "otherExpenseHistory",
        component: () => import("@/views/capital/otherExpenseHistory"),
        meta: {
          title: "其他支出单记录",
        },
      },
      {
        path: "otherExpense",
        name: "otherExpense",
        component: () => import("@/views/capital/otherExpense"),
        meta: {
          title: "其他支出单",
        },
      },
    ],
  },
  {
    path: "/reportForm",
    name: "reportForm",
    component: Layout,
    meta: {
      title: "报表",
    },
    children: [
      {
        path: "puDetail",
        name: "puDetail",
        component: () => import("@/views/reportForm/puDetail"),
        meta: {
          title: "采购明细表",
        },
      },
      {
        path: "puSummary",
        name: "puSummary",
        component: () => import("@/views/reportForm/puSummary"),
        meta: {
          title: "采购总汇表(商品)",
        },
      },
      {
        path: "puSummarySupply",
        name: "puSummarySupply",
        component: () => import("@/views/reportForm/puSummarySupply"),
        meta: {
          title: "采购总汇表(供应商)",
        },
      },
      {
        path: "salesDetail",
        name: "salesDetail",
        component: () => import("@/views/reportForm/salesDetail"),
        meta: {
          title: "销售明细表",
        },
      },
      {
        path: "salesSummary",
        name: "salesSummary",
        component: () => import("@/views/reportForm/salesSummary"),
        meta: {
          title: "销售总汇表(商品)",
        },
      },
      {
        path: "salesSummaryCustomer",
        name: "salesSummaryCustomer",
        component: () => import("@/views/reportForm/salesSummaryCustomer"),
        meta: {
          title: "销售总汇表(客户)",
        },
      },
      {
        path: "contactDebt",
        name: "contactDebt",
        component: () => import("@/views/reportForm/contactDebt"),
        meta: {
          title: "来往单位欠款表",
        },
      },
      {
        path: "salesProfit",
        name: "salesProfit",
        component: () => import("@/views/reportForm/salesProfit"),
        meta: {
          title: "销售利润表",
        },
      },
      {
        path: "initialBalance",
        name: "initialBalance",
        component: () => import("@/views/reportForm/initialBalance"),
        meta: {
          title: "商品库存余额表",
        },
      },
      {
        path: "goodsFlowDetail",
        name: "goodsFlowDetail",
        component: () => import("@/views/reportForm/goodsFlowDetail"),
        meta: {
          title: "商品收发明细表",
        },
      },
      {
        path: "goodsFlowSummary",
        name: "goodsFlowSummary",
        component: () => import("@/views/reportForm/goodsFlowSummary"),
        meta: {
          title: "商品收发总汇表",
        },
      },
      {
        path: "cashBankJournal",
        name: "cashBankJournal",
        component: () => import("@/views/reportForm/cashBankJournal"),
        meta: {
          title: "现金银行报表",
        },
      },
      {
        path: "accountPayDetail",
        name: "accountPayDetail",
        component: () => import("@/views/reportForm/accountPayDetail"),
        meta: {
          title: "应付帐款明细表",
        },
      },
      {
        path: "accountProceedsDetail",
        name: "accountProceedsDetail",
        component: () => import("@/views/reportForm/accountProceedsDetail"),
        meta: {
          title: "应收帐款明细表",
        },
      },
      {
        path: "customersReconciliation",
        name: "customersReconciliation",
        component: () => import("@/views/reportForm/customersReconciliation"),
        meta: {
          title: "客户对账单",
        },
      },
      {
        path: "suppliersReconciliation",
        name: "suppliersReconciliation",
        component: () => import("@/views/reportForm/suppliersReconciliation"),
        meta: {
          title: "供应商对账单",
        },
      },
      {
        path: "otherIncomeExpenseDetail",
        name: "otherIncomeExpenseDetail",
        component: () => import("@/views/reportForm/otherIncomeExpenseDetail"),
        meta: {
          title: "其他收支明细表",
        },
      },
    ],
  },
  {
    path: "/goods",
    name: "goods",
    component: Layout,
    meta: {
      title: "商品",
    },
    children: [
      {
        path: "goodsList",
        name: "goodsList",
        component: () => import("@/views/goods/index"),
        meta: {
          title: "商品列表",
        },
      },
      {
        path: "typelist",
        name: "typelist",
        component: () => import("@/views/commoditytype/typelist"),
        meta: {
          title: "商品类型",
        },
      },
      {
        path: "attributelist",
        name: "attributelist",
        component: () => import("@/views/commoditytype/attributelist"),
        meta: {
          hidden: true,
          title: "属性列表",
        },
      },
      {
        path: "parameterlist",
        name: "parameterlist",
        component: () => import("@/views/commoditytype/parameterlist"),
        meta: {
          hidden: true,
          title: "参数列表",
        },
      },
      {
        path: "editGoods",
        name: "EditGoods",
        component: () => import("@/views/goods/editGoods"),
        meta: {
          title: "商品编辑",
          hidden: true,
        },
      },
      {
        path: "goodsCategories",
        name: "goodsCategories",
        component: () => import("@/views/goods/goodsCategories"),
        meta: {
          title: "商品分类",
        },
      },
      {
        path: "setWords",
        name: "setWords",
        component: () => import("@/views/tools/setWords"),
        meta: {
          title: "商品埋词",
        },
      },
      {
        path: "setGoodsBase",
        name: "setGoodsBase",
        component: () => import("@/views/tools/setGoodsBase"),
        meta: {
          title: "批量修改基本信息",
        },
      },
      {
        path: "setGoodsDetail",
        name: "setGoodsDetail",
        component: () => import("@/views/tools/setGoodsDetail"),
        meta: {
          title: "批量修改详情",
        },
      },
      {
        path: "goodsUpload",
        name: "goodsUpload",
        component: () => import("@/views/goods/goodsUpload"),
        meta: {
          title: "导入商品",
        },
      },
      {
        path: "setGoodsTime",
        name: "setGoodsTime",
        component: () => import("@/views/tools/setGoodsTime"),
        meta: {
          title: "批量上下架",
        },
      },
      {
        path: "setGoodsPic",
        name: "setGoodsPic",
        component: () => import("@/views/tools/setGoodsPic"),
        meta: {
          title: "批量修改图片",
        },
      },
      {
        path: "transferClassification",
        name: "transferClassification",
        component: () => import("@/views/tools/transferClassification"),
        meta: {
          title: "修改分类",
        },
      },
      {
        path: "setLot",
        name: "setLot",
        component: () => import("@/views/tools/setLot"),
        meta: {
          title: "批量修改",
          hidden: true,
        },
      },
      {
        path: "setOnce",
        name: "setOnce",
        component: () => import("@/views/tools/setOnce"),
        meta: {
          title: "逐一修改",
          hidden: true,
        },
      },
      {
        path: "browseRecords",
        name: "browseRecords",
        component: () => import("@/views/goods/browseRecords"),
        meta: {
          hidden: true,
          title: "浏览记录",
        },
      },
      {
        path: "commodityAudit",
        name: "commodityAudit",
        component: () => import("@/views/goods/commodityAudit"),
        meta: {
          title: "商品审核",
        },
      },
      {
        path: "commodityRecycleBin",
        name: "commodityRecycleBin",
        component: () => import("@/views/goods/commodityRecycleBin"),
        meta: {
          title: "商品回收站",
        },
      },
      {
        path: "commodityEvaluation",
        name: "commodityEvaluation",
        component: () => import("@/views/goods/commodityEvaluation"),
        meta: {
          title: "商品评论",
        },
      },
      {
        path: "goodsReview",
        name: "goodsReview",
        component: () => import("@/views/goods/goodsReview"),
        meta: {
          title: "评论详情",
          hidden: true,
        },
      },
      {
        path: "brand",
        name: "brand",
        component: () => import("@/views/goods/brand"),
        meta: {
          title: "品牌",
        },
      },
      // {
      //   path:"salesSetup/salesSetup",
      //   name:"salesSetup",
      //   component:()=>import('@/views/globalSettings/salesSetup'),
      //   meta:{
      //     title: "销售设置"
      //   }
      // },
    ],
  },
  {
    path: "/order",
    name: "order",
    component: Layout,
    meta: {
      title: "订单",
    },
    children: [
      {
        path: "orderList",
        name: "orderList",
        component: () => import("@/views/order/orderList"),
        meta: {
          title: "订单列表",
        },
      },
      {
        path: "orderDetail",
        name: "orderDetail",
        component: () => import("@/views/order/orderDetail"),
        meta: {
          title: "订单详情",
          hidden: true,
        },
      },
      {
        path: "confirmOrderList",
        name: "confirmOrderList",
        component: () => import("@/views/order/confirmOrderList"),
        meta: {
          title: "收货管理",
        },
      },
      {
        path: "orderTransfer",
        name: "orderTransfer",
        component: () => import("@/views/order/orderTransfer"),
        meta: {
          title: "订单转账"
        }
      }
    ],
  },
  {
    path: "/shipping",
    name: "shipping",
    component: Layout,
    meta: {
      title: "发货",
    },
    children: [
      {
        path: "shippingList",
        name: "shippingList",
        component: () => import("@/views/shipping/shippingList"),
        meta: {
          title: "发货管理",
        },
      },
      {
        path: "distributionList",
        name: "distributionList",
        component: () => import("@/views/shipping/distributionList"),
        meta: {
          title: "配货单管理",
        },
      },
      {
        path: "arrivalReminder",
        name: "arrivalReminder",
        component: () => import("@/views/order/arrivalReminder"),
        meta: {
          title: "到货提醒",
        },
      },
      {
        path: "arrivalReminderReport",
        name: "arrivalReminderReport",
        component: () => import("@/views/order/arrivalReminderReport"),
        meta: {
          hidden: true,
          title: "到货提醒报表",
        },
      },
      {
        path: "deliveryPoint",
        name: "deliveryPoint",
        component: () => import("@/views/deliveryPoint"),
        meta: {
          title: "发货点设置",
        },
      },
    ],
  },
  {
    path: "/afterSale",
    name: "afterSale",
    component: Layout,
    meta: {
      title: "售后",
    },
    children: [
      {
        path: "afterSaleList",
        name: "afterSaleList",
        component: () => import("@/views/afterSale/afterSaleList"),
        meta: {
          title: "售后管理",
        },
      },
      {
        path: "afterSale",
        name: "afterSale",
        component: () => import("@/views/afterSale/afterSale"),
        meta: {
          title: "新增售后",
          hidden: true,
        },
      },
      {
        path: "afterSaleDetail",
        name: "afterSaleDetail",
        component: () => import("@/views/afterSale/afterSaleDetail"),
        meta: {
          title: "售后详情",
          hidden: true,
        },
      },
      {
        path: "returnAndExchange",
        name: "returnAndExchange",
        component: () => import("@/views/returnAndExchange"),
        meta: {
          title: "退换货原因类型",
        },
      },
      {
        path: "refundsList",
        name: "refundsList",
        component: () => import("@/views/afterSale/refundsList"),
        meta: {
          title: "退款管理",
        },
      },
    ],
  },
  {
    path: "/inquiry",
    name: "inquiry",
    component: Layout,
    meta: {
      title: "询盘",
    },
    children: [
      {
        path: "inquiry",
        name: "inquiry",
        component: () => import("@/views/inquiry/inquiry"),
        meta: {
          title: "询盘",
        },
      },
      {
        path: "inquiryDetail",
        name: "inquiryDetail",
        component: () => import("@/views/inquiry/inquiryDetail"),
        meta: {
          title: "询盘详情",
          hidden: true,
        },
      },
      // {
      //   path:"createQuotation",
      //   name:"createQuotation",
      //   component:()=>import('@/views/inquiry/createQuotation'),
      //   meta:{
      //     title: "创建报价单",
      //     hidden:true
      //   }
      // },
      {
        path: "inquiryCreateOrder",
        name: "inquiryCreateOrder",
        component: () => import("@/views/inquiry/inquiryCreateOrder"),
        meta: {
          title: "新建订单",
          hidden: true,
        },
      },
      {
        path: "link",
        name: "link",
        component: () => import("@/views/link"),
        meta: {
          title: "链接",
        },
      },
      {
        path: "replyTemplate",
        name: "replyTemplate",
        component: () => import("@/views/replyTemplate"),
        meta: {
          title: "回复模板",
        },
      },
      {
        path: "settings",
        name: "settings",
        component: () => import("@/views/globalSettings"),
        meta: {
          title: "询盘设置",
        },
      },
    ],
  },
  {
    path:'/blog',
    name: 'blog',
    component: Layout,
    meta: {
      title: "BLOG"
    },
    children: [
      {
        path: "blogList",
        name: "blogList",
        component: () => import("@/views/blog/BlogList"),
        meta: {
          title: "BLOG列表",
        },
      },
      {
        path: "blogCate",
        name: "blogCate",
        component: () => import("@/views/blog/BlogCate"),
        meta: {
          title: "BLOG分类列表",
        },
      },
      {
        path: "release",
        name: "release",
        component: () => import("@/views/blog/release"),
        meta: {
          title: "发布",
          hidden: true,
          noKeepAlive: true
        },
      },
    ]
  },
  {
    path:'/article',
    name: 'article',
    component: Layout,
    meta: {
      title: "文章"
    },
    children: [
      {
        path: "articleList",
        name: "articleList",
        component: () => import("@/views/article/articleList"),
        meta: {
          title: "文章列表",
        },
      },
      {
        path: "articleCate",
        name: "articleCate",
        component: () => import("@/views/article/articleCate"),
        meta: {
          title: "文章分类列表",
        },
      },
      {
        path: "release",
        name: "release",
        component: () => import("@/views/article/release"),
        meta: {
          title: "发布",
          hidden: true,
          noKeepAlive: true
        },
      },
    ]
  },
  {
    path: "/wholesale",
    name: "wholesale",
    component: Layout,
    meta: {
      title: "批发",
    },
    children: [
      {
        path: "wholesaleList",
        name: "wholesaleList",
        component: () => import("@/views/wholesale/index"),
        meta: {
          title: "单品列表",
        },
      },
      {
        path: "wholesale",
        name: "wholesale",
        component: () => import("@/views/wholesale/wholesale"),
        meta: {
          title: "单品批发",
        },
      },
      {
        path: "globalWholesale",
        name: "globalWholesale",
        component: () => import("@/views/wholesale/globalWholesale"),
        meta: {
          title: "全局",
        },
      },
      {
        path: "cateWholesale",
        name: "cateWholesale",
        component: () => import("@/views/wholesale/cateWholesale"),
        meta: {
          title: "分类",
        },
      },
    ],
  },
  {
    path: "/user",
    name: "user",
    component: Layout,
    meta: {
      title: "用户",
    },
    children: [
      {
        path: "userList",
        name: "userList",
        component: () => import("@/views/user/list"),
        meta: {
          title: "用户列表",
        },
      },
      {
        path: "userDetails",
        name: "userDetails",
        component: () => import("@/views/user/userDetails"),
        meta: {
          hidden: true,
          title: "用户详情",
        },
      },
      {
        path: "user",
        name: "user",
        component: () => import("@/views/user"),
        meta: {
          title: "用户筛选",
        },
      },
      {
        path: "userLevel",
        name: "userLevel",
        component: () => import("@/views/user/userLevel"),
        meta: {
          title: "用户等级",
        },
      },
      {
        path: "userLabelList",
        name: "userlabel",
        component: () => import("@/views/user_label/index"),
        meta: {
          title: "用户标签",
        },
      },
      {
        path: "personalAssets",
        name: "personalAssets",
        component: () => import("@/views/user/personalAssets"),
        meta: {
          title: "个人资产",
        },
      },
      {
        path: "obviousIntegral",
        name: "obviousIntegral",
        component: () => import("@/views/user/obviousIntegral"),
        meta: {
          hidden: true,
          title: "积分明细",
        },
      },
      {
        path: "growthValueDetails",
        name: "growthValueDetails",
        component: () => import("@/views/user/growthValueDetails"),
        meta: {
          hidden: true,
          title: "成长值明细",
        },
      },
      {
        path: "Plus",
        name: "Plus",
        component: () => import("@/views/Plus"),
        meta: {
          title: "Plus会员",
        },
      },
      {
        path: "PlusSetUp",
        name: "PlusSetUp",
        component: () => import("@/views/Plus/PlusSetUp"),
        meta: {
          title: "Plus会员设置",
          hidden: true,
        },
      },
      {
        path: "PlusReportForm",
        name: "PlusReportForm",
        component: () => import("@/views/Plus/PlusReportForm"),
        meta: {
          hidden: true,
          title: "Plus会员数据报表",
        },
      },
    ],
  },
  {
    path: "/sale",
    name: "sale",
    component: Layout,
    meta: {
      title: "销售",
    },
    children: [
      {
        path: "limitedTimeSecondKill",
        name: "limitedTimeSecondKill",
        component: () => import("@/views/limitedTimeSecondKill"),
        meta: {
          title: "限时秒杀",
        },
      },
      {
        path: "limitedTimeSecondKill/activityGoods",
        name: "activityGoods",
        component: () => import("@/views/limitedTimeSecondKill/activityGoods"),
        meta: {
          hidden: true,
          title: "秒杀商品",
        },
      },
      {
        path: "limitedTimeSecondKill/revise",
        name: "limitedTimeSecondKill/revise",
        component: () => import("@/views/limitedTimeSecondKill/revise"),
        meta: {
          hidden: true,
          title: "新增/修改限时秒杀",
        },
      },
      {
        path: "fieldSetting",
        name: "fieldSetting",
        component: () => import("@/views/limitedTimeSecondKill/fieldSetting"),
        meta: {
          hidden: true,
          title: "场次设置",
        },
      },

      {
        path: "rechargePackage",
        name: "rechargePackage",
        component: () => import("@/views/rechargePackage"),
        meta: {
          title: "充值套餐",
        },
      },
      {
        path: "rechargePackage/awardList",
        name: "awardList",
        component: () => import("@/views/rechargePackage/awardList"),
        meta: {
          hidden: true,
          title: "套餐奖励列表",
        },
      },
      {
        path: "rechargePackage/revise",
        name: "rechargePackage/revise",
        component: () => import("@/views/rechargePackage/revise"),
        meta: {
          hidden: true,
          title: "新增/修改充值套餐",
        },
      },
      {
        path: "rechargePackageSettings",
        name: "rechargePackageSettings",
        component: () =>
          import("@/views/rechargePackage/rechargePackageSettings"),
        meta: {
          hidden: true,
          title: "充值套餐设置",
        },
      },
      {
        path: "consumptionReward",
        name: "consumptionReward",
        component: () => import("@/views/consumptionReward"),
        meta: {
          title: "消费奖励",
        },
      },
      {
        path: "consumptionReward/revise",
        name: "consumptionReward/revise",
        component: () => import("@/views/consumptionReward/revise"),
        meta: {
          hidden: true,
          title: "设置消费奖励",
        },
      },
      {
        path: "fullPackage",
        name: "fullPackage",
        component: () => import("@/views/fullPackage"),
        meta: {
          title: "满额包邮",
        },
      },
      {
        path: "fullPackageSettings",
        name: "fullPackageSettings",
        component: () => import("@/views/fullPackage/fullPackageSettings"),
        meta: {
          hidden: true,
          title: "满额包邮设置",
        },
      },
      {
        path: "bargainGoods",
        name: "bargainGoods",
        component: () => import("@/views/bargainGoods"),
        meta: {
          title: "砍价商品",
        },
      },
      {
        path: "bargainGoods/revise",
        name: "bargainGoods/revise",
        component: () => import("@/views/bargainGoods/revise"),
        meta: {
          hidden: true,
          title: "新增/修改砍价商品",
        },
      },
      {
        path: "bargainingList",
        name: "bargainingList",
        component: () => import("@/views/bargainGoods/bargainingList"),
        meta: {
          hidden: true,
          title: "砍价列表",
        },
      },
      {
        path: "helpChopRecords",
        name: "helpChopRecords",
        component: () => import("@/views/bargainGoods/helpChopRecords"),
        meta: {
          hidden: true,
          title: "帮砍记录",
        },
      },
      {
        path: "bargainItemSettings",
        name: "bargainItemSettings",
        component: () => import("@/views/bargainGoods/bargainItemSettings"),
        meta: {
          hidden: true,
          title: "砍价商品设置",
        },
      },
      {
        path: "combinationPackage",
        name: "combinationPackage",
        component: () => import("@/views/combinationPackage"),
        meta: {
          title: "组合套餐",
        },
      },
      {
        path: "combinationPackage/revise",
        name: "combinationPackage/revise",
        component: () => import("@/views/combinationPackage/revise"),
        meta: {
          hidden: true,
          title: "新增/修改组合套餐",
        },
      },
      {
        path: "timeLimitedDiscount",
        name: "timeLimitedDiscount",
        component: () => import("@/views/timeLimitedDiscount"),
        meta: {
          title: "限时折扣",
        },
      },
      {
        path: "timeLimitedDiscount/revise",
        name: "timeLimitedDiscount/revise",
        component: () => import("@/views/timeLimitedDiscount/revise"),
        meta: {
          hidden: true,
          title: "新增/修改限时折扣",
        },
      },
      {
        path: "timeLimitedDiscountDetails",
        name: "timeLimitedDiscountDetails",
        component: () =>
          import("@/views/timeLimitedDiscount/timeLimitedDiscountDetails"),
        meta: {
          hidden: true,
          title: "限时折扣详情",
        },
      },
      {
        path: "groupBuying",
        name: "groupBuying",
        component: () => import("@/views/groupBuying"),
        meta: {
          title: "团购",
        },
      },
      {
        path: "groupBuying/revise",
        name: "groupBuying/revise",
        component: () => import("@/views/groupBuying/revise"),
        meta: {
          hidden: true,
          title: "新增/修改团购",
        },
      },
      {
        path: "fullMinus",
        name: "fullMinus",
        component: () => import("@/views/fullMinus"),
        meta: {
          title: "满减活动",
        },
      },
      {
        path: "fullMinus/addReduction",
        name: "addReduction",
        component: () => import("@/views/fullMinus/addReduction"),
        meta: {
          hidden: true,
          title: "编辑满减活动",
        },
      },
      {
        path: "collage",
        name: "collage",
        component: () => import("@/views/collage"),
        meta: {
          title: "拼团",
        },
      },
      {
        path: "collage/revise",
        name: "collage/revise",
        component: () => import("@/views/collage/revise"),
        meta: {
          hidden: true,
          title: "新增/修改拼团",
        },
      },
      {
        path: "groupDetails",
        name: "groupDetails",
        component: () => import("@/views/collage/groupDetails"),
        meta: {
          hidden: true,
          title: "拼团详情",
        },
      },
      {
        path: "preSale",
        name: "preSale",
        component: () => import("@/views/preSale"),
        meta: {
          title: "预售",
        },
      },
      {
        path: "preSale/revise",
        name: "preSale/revise",
        component: () => import("@/views/preSale/revise"),
        meta: {
          hidden: true,
          title: "新增/修改预售",
        },
      },

      {
        path: "thematicActivities",
        name: "thematicActivities",
        component: () => import("@/views/thematicActivities"),
        meta: {
          title: "专题活动",
        },
      },
      {
        path: "thematicActivities/revise",
        name: "thematicActivities/revise",
        component: () => import("@/views/thematicActivities/revise"),
        meta: {
          hidden: true,
          title: "新增/修改专题活动",
        },
      },
      {
        path: "paymentBeforeReturn",
        name: "paymentBeforeReturn",
        component: () => import("@/views/paymentBeforeReturn"),
        meta: {
          title: "先付后返",
        },
      },
      {
        path: "modifyPaymentBeforeReturn",
        name: "modifyPaymentBeforeReturn",
        component: () =>
          import("@/views/paymentBeforeReturn/modifyPaymentBeforeReturn"),
        meta: {
          hidden: true,
          title: "新增/修改先付后返",
        },
      },
      {
        path: "participationList",
        name: "participationList",
        component: () =>
          import("@/views/paymentBeforeReturn/participationList"),
        meta: {
          hidden: true,
          title: "参与列表",
        },
      },
      {
        path: "userParticipationList",
        name: "userParticipationList",
        component: () =>
          import("@/views/paymentBeforeReturn/userParticipationList"),
        meta: {
          hidden: true,
          title: "用户列表跳转",
        },
      },
    ],
  },
  {
    path: "/coupon",
    name: "coupon",
    component: Layout,
    meta: {
      title: "优惠券",
    },
    children: [
      {
        path: "coupon",
        name: "coupon",
        component: () => import("@/views/coupon"),
        meta: {
          title: "优惠券",
        },
      },
      {
        path: "editorcoupons",
        name: "editorcoupons",
        component: () => import("@/views/commoditytype/editorcoupons"),
        meta: {
          hidden: true,
          title: "编辑优惠券",
        },
      },
      {
        path: "receiptOfCoupons",
        name: "receiptOfCoupons",
        component: () => import("@/views/coupon/receiptOfCoupons"),
        meta: {
          hidden: true,
          title: "领取情况",
        },
      },
      {
        path: "reviewCoupons",
        name: "reviewCoupons",
        component: () => import("@/views/coupon/reviewCoupons"),
        meta: {
          title: "审核优惠券",
        },
      },
    ],
  },
  // {
  //   path: "/BOM",
  //   name: "BOM",
  //   component: Layout,
  //   meta: {
  //     title: "BOM",
  //   },
  //   children: [
  //     {
  //       path: "bom",
  //       name: "bom",
  //       component: () => import("@/views/bom"),
  //       meta: {
  //         title: "BOM",
  //       },
  //     },
  //     {
  //       path: "tagList",
  //       name: "tagList",
  //       component: () => import("@/views/bom/tagList"),
  //       meta: {
  //         title: "标签列表",
  //       },
  //     },
  //   ],
  // },
  {
    path: "/marketing",
    name: "marketing",
    component: Layout,
    meta: {
      title: "营销",
    },
    children: [
      {
        path: "jiugongge",
        name: "jiugongge",
        component: () => import("@/views/jiugongge"),
        meta: {
          title: "九宫格",
        },
      },
      {
        path: "jiugongge/revise",
        name: "jiugongge/revise",
        component: () => import("@/views/jiugongge/revise"),
        meta: {
          hidden: true,
          title: "新增/修改九宫格",
        },
      },
      {
        path: "jiugonggeWinningRecord",
        name: "jiugonggeWinningRecord",
        component: () => import("@/views/jiugongge/jiugonggeWinningRecord"),
        meta: {
          hidden: true,
          title: "九宫格中奖记录",
        },
      },
      {
        path: "giftsForNewcomers",
        name: "giftsForNewcomers",
        component: () => import("@/views/giftsForNewcomers"),
        meta: {
          title: "新人有礼",
        },
      },
      {
        path: "newGiftGivingSettings",
        name: "newGiftGivingSettings",
        component: () =>
          import("@/views/giftsForNewcomers/newGiftGivingSettings"),
        meta: {
          hidden: true,
          title: "新人送礼设置",
        },
      },
      {
        path: "newcomerRewardHistory",
        name: "newcomerRewardHistory",
        component: () =>
          import("@/views/giftsForNewcomers/newcomerRewardHistory"),
        meta: {
          hidden: true,
          title: "新人送礼奖励历史",
        },
      },
    ],
  },
  {
    path: "/ip",
    name: "ip",
    component: Layout,
    meta: {
      title: "IP",
    },
    children: [
      {
        path: "shieldIP",
        name: "shieldIP",
        component: () => import("@/views/shield/shieldIP"),
        meta: {
          title: "屏蔽IP",
        },
      },
      {
        path: "accessPassword",
        name: "accessPassword",
        component: () => import("@/views/shield/accessPassword"),
        meta: {
          title: "访问密码",
        },
      },
    ],
  },
  {
    path: "/internationality",
    name: "internationality",
    component: Layout,
    meta: {
      title: "国际",
    },
    children: [
      {
        path: "multiCurrency",
        name: "multiCurrency",
        component: () => import("@/views/multiCurrency"),
        meta: {
          title: "多币种",
        },
      },
      {
        path: "multiCurrency/languagelist",
        name: "languagelist",
        component: () => import("@/views/multiCurrency/languagelist"),
        meta: {
          title: "多语言",
        },
      },
      {
        path: "multiCurrency/translationlist",
        name: "translationlist",
        component: () => import("@/views/multiCurrency/translationlist"),
        meta: {
          hidden: true,
          title: "翻译列表",
        },
      },
      {
        path: "multiCurrency/ratelist",
        name: "ratelist",
        component: () => import("@/views/multiCurrency/ratelist"),
        meta: {
          title: "税率列表",
        },
      },
    ],
  },
  {
    path: "/logistics",
    name: "logistics",
    component: Layout,
    meta: {
      title: "物流",
    },
    children: [
      {
        path: "logistics/setlogistics",
        name: "setlogistics",
        component: () => import("@/views/logistics/setlogistics"),
        meta: {
          title: "设置页面",
        },
      },
      {
        path: "logistics/courierDemoList",
        name: "courierDemoList",
        component: () => import("@/views/logistics/courierDemoList"),
        meta: {
          title: "运费模板列表",
        },
      },
      {
        path: "logistics/freightDemo",
        name: "freightDemo",
        component: () => import("@/views/logistics/freightDemo"),
        meta: {
          hidden: true,
          title: "运费模板",
        },
      },
      {
        path: "regionalManagement",
        name: "regionalManagement",
        component: () => import("@/views/regionalManagement"),
        meta: {
          title: "区域管理",
        },
      },
      {
        path: "areaLabel",
        name: "areaLabel",
        component: () => import("@/views/logistics/areaLabel"),
        meta: {
          title: "区域标签",
        },
      },
      {
        path: "countrySettings",
        name: "countrySettings",
        component: () => import("@/views/logistics/countrySettings"),
        meta: {
          title: "国家设置",
        },
      },
      {
        path: "courierCompany",
        name: "courierCompany",
        component: () => import("@/views/logistics/courierCompany"),
        meta: {
          title: "快递公司",
        },
      },
    ],
  },
  {
    path: "/advertise",
    name: "advertise",
    component: Layout,
    meta: {
      title: "广告",
    },
    children: [
      {
        path: "advertisement",
        name: "advertisement",
        component: () => import("@/views/advertisement/index"),
        meta: {
          title: "广告",
        },
      },
      {
        path: "advertisementDetail",
        name: "advertisementDetail",
        component: () => import("@/views/advertisement/advertisementDetail"),
        meta: {
          title: "广告详情",
          hidden: true
        },
      },
    ],
  },
  {
    path: "/notice",
    name: "notice",
    component: Layout,
    meta: {
      title: "通知",
    },
    children: [
      {
        path: "PM",
        name: "PM",
        component: () => import("@/views/PM"),
        meta: {
          title: "list管理",
        },
      },
    ],
  },
  {
    path: "/webmail",
    name: "webmail",
    component: Layout,
    meta: {
      title: "Webmail扩展",
    },
    children: [
      {
        path: "extensionlist",
        name: "extensionlist",
        component: () => import("@/views/webmail/extensionlist"),
        meta: {
          title: "扩展列表",
        },
      },
      {
        path: "redactEmail",
        name: "redactEmail",
        component: () => import("@/views/webmail/redactEmail"),
        meta: {
          hidden: true,
          title: "编辑邮件",
        },
      },
      {
        path: "EmailSettings",
        name: "EmailSettings",
        component: () => import("@/views/webmail/EmailSettings"),
        meta: {
          title: "重点客户设置",
        },
      },
    ],
  },
  {
    path: "/priceList",
    name: "priceList",
    component: Layout,
    meta: {
      title: "内部询价",
    },
    children: [
      {
        path: "pirceCard",
        name: "pirceCard",
        component: () => import("@/views/priceList/pirceCard"),
        meta: {
          title: "询价卡片",
        },
      },
    ],
  },
  {
    path: "/maildirectory",
    name: "maildirectory",
    component: Layout,
    meta: {
      title: "邮件",
    },
    children: [
      {
        path: "index",
        name: "maildirectory",
        component: () => import("@/views/maildirectory/index"),
        meta: {
          title: "邮件模板",
        },
      },
    ],
  },
  {
    path: "/settings",
    name: "settings",
    component: Layout,
    meta: {
      title: "设置",
    },
    children: [
      {
        path: "payment",
        name: "payment",
        component: () => import("@/views/payment/index"),
        meta: {
          title: "支付设置",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
