<template>
  <div id="mainPage">
    <keep-alive >
      <router-view :key="$route.fullPath" v-if="!$route.meta.noKeepAlive"/>
    </keep-alive>
      <router-view :key="$route.fullPath" v-if="$route.meta.noKeepAlive"/>
  </div>
</template>

<script>
export default {
  name: "AppMain",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style>
#mainPage {
  width: 100%;
  height: calc(100% - 41px);
  box-sizing: border-box;
  padding: 20px;
  box-sizing: border-box;
}

</style>
