<template>
  <div class="frame">
  </div>
</template>
<script>

export default {
  name: 'frame',
  methods: {
  }
}
</script>
<style lang="less" scoped>
</style>
