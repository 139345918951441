<template>
  <div>
    <textarea :id="tinymceId" style="visibility: hidden" />
    <fileSelect :visible.sync="showSelecter" title="选择图片" state="image" multiple="1" append-to-body @confirm="showSelecterSubmit"></fileSelect>
  </div>
</template>

<script>
import loadTinymce from '@/utils/loadTinymce'
import { plugins, toolbar } from './config'
import { debounce } from 'throttle-debounce'
import axios from 'axios'
import fileSelect  from "@/views/components/fileSelect";
let num = 1

export default {
  components: {
    fileSelect
  },
  props: {
    id: {
      type: String,
      default: () => {
        num === 10000 && (num = 1)
        return `tinymce${+new Date()}${num++}`
      }
    },
    value: {
      default: ''
    }
  },
  data() {
    return {
      tinymceId: this.id,
      showSelecter: false,
    }
  },
  mounted() {
    let that = this
    loadTinymce(tinymce => {
      // eslint-disable-next-line global-require
      require('./zh_CN')
      let conf = {
        selector: `#${this.tinymceId}`,
        language: 'zh_CN',
        height: 300,
        width: 1200,
          menubar: 'edit insert view format table',
        plugins: 'link lists image code table colorpicker textcolor wordcount contextmenu fullscreen',
        toolbar:
            'fullscreen | bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | undo redo | link unlink customBtn code | removeformat ',
        branding: false,
        images_upload_handler: (blobInfo, success, failure) => {
          this.handleImgUpload(blobInfo, success, failure)
        },
        setup: editor => {
            editor.ui.registry.addButton('customBtn',{
                tooltip: '自定义工具栏按钮',
                icon:'Image',
                onAction: () => {
                  that.showSelecter = true
                }
              })
            }
      }
      conf = Object.assign(conf, this.$attrs)
      conf.init_instance_callback = editor => {
        if (this.value) editor.setContent(this.value)
        this.vModel(editor)
      }
      tinymce.init(conf)
    })
  },
  destroyed() {
    this.destroyTinymce()
  },
  methods: {
    inputContent(e) {
      this.value += e
      this.$emit('input', this.value)
    },
    showSelecterSubmit(data){
      var picList=data
      picList.forEach(item => {
        var strHtml = `\n<img src="${item.origin}" style="display:block;width:100%;object-fit: contain"/>`
        this.value += strHtml
      })
      this.$emit('input', this.value)
    },
    vModel(editor) {
      // 控制连续写入时setContent的触发频率
      const debounceSetContent = debounce(250, editor.setContent)
      this.$watch('value', (val, prevVal) => {
        if (editor && val !== prevVal && val !== editor.getContent()) {
          if (typeof val !== 'string') val = val.toString()
          debounceSetContent.call(editor, val)
        }
      })

      editor.on('change keyup undo redo', () => {
        this.$emit('input', editor.getContent())
      })
    },
    destroyTinymce() {
      if (!window.tinymce) return
      const tinymce = window.tinymce.get(this.tinymceId)
      if (tinymce) {
        tinymce.destroy()
      }
    },
    handleImgUpload (blobInfo, success, failure) {
      let formdata = new FormData()
      formdata.append('file', blobInfo.blob())
      console.log(formdata)
      axios({
        method: "post", // post方法
        url: 'https://api.51jpy.cn/base/upload/upload-file', // 请求上传图片服务器的路径
        headers: {
          Authorization: 'Bearer b64b5a1ed51c5380dd3d33960528b7ba',
          role: 'admin'
        },
        data: formdata // 请求数据formdata
      }).then(res => {
        if (res.data.code != 200) {
          // 上传失败执行此方法，将失败信息填入参数中
          failure('HTTP Error: ' + res.message);
          return
        }
        // 上传成功之后，将对应完整的图片路径拼接在success的参数中
        success(res.data);
      })

    }
  }
}
</script>
