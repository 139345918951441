<template>
  <div>
    <top-bar @showtranlate="translateDialog = true"/>
    <div class="columnContainer">
      <columnBar/>
      <div class="mainContainer">
        <dk-nav/>
        <el-scrollbar>
          <app-main/>
        </el-scrollbar>
      </div>
    </div>
    <transition name="el-fade-in-linear">
      <div  v-hotkey="keymap" v-show="translateDialog" class="translateDialog">
        <i class="el-icon-close iconClose" @click="translateDialog = false"></i>
        <el-card style="flex-grow: 1;margin: 10px">
          <el-input type="textarea" :rows="10" v-model="value1"></el-input>
          <i class="el-icon-copy-document icon click_copy" @click="toolClipboardCopy(value1)"></i>
        </el-card>
        <el-card style="flex-grow: 1;margin: 10px">
          <el-input type="textarea" :rows="10" v-model="value2"></el-input>
          <i class="el-icon-copy-document icon click_copy" @click="toolClipboardCopy(value2)"></i>
        </el-card>
        <el-card style="flex-grow: 1;margin: 10px">
          <el-input type="textarea" :rows="10" v-model="value3"></el-input>
          <i class="el-icon-copy-document icon click_copy" @click="toolClipboardCopy(value3)"></i>
        </el-card>
      </div>
    </transition>
  </div>
</template>

<script>
import AppMain from '@/dkui/component/appMain'
import columnBar from '@/dkui/component/columnBar'
import DkNav from '@/dkui/component/dkNav'
import TopBar from '@/dkui/component/topBar'
import Clipboard from 'clipboard'
export default {
  name: 'Column',
  components: { TopBar, columnBar, AppMain,DkNav},
  data(){
    return {
      translateDialog:false,
      value1:'',
      value2:'',
      value3:''
    }
  },
  computed: {
    keymap () {
      return {
        'shift+f1': this.toggle,
      }
    }
  },
  methods:{
    toggle() {
      this.translateDialog = !this.translateDialog
    },
    toolClipboardCopy(text){
      let clipboard = new Clipboard(".click_copy", {
        text: function() {
          return text
        }
      })
      clipboard.on('success', e => {
        this.$message({ message: '复制成功', type: 'success' })
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        this.$message({ message: '复制失败,', type: 'error' })
        clipboard.destroy()
      })
    }
  },
  created () {

  },
}
</script>

<style lang="less" scoped>
.columnContainer {
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: row;
  .mainContainer {
    width: calc(100vw - 200px);
    display: flex;
    flex-direction: column;
  }
}

.translateDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.3);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  box-sizing: border-box;
  .icon {
    font-size: 24px;
    margin-top: 10px;
    cursor: pointer;
  }
  .iconClose {
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 30px;
    color: #FFFFFF;
  }
}
</style>
